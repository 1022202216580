import React, { useState } from 'react'
import TextInput from '../components/text_input'
import Checkbox from '../components/checkbox'
import Button from '../components/button'
import { Formik, Form, Field } from 'formik'

const CCPAForm = () => {
  
  const [isFormComplete, setComplete] = useState(false)

  return (<>
    {isFormComplete ||
      <Formik
        initialValues={{
          email: null,
          phone: null,
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
          name: null
        }}
        onSubmit={(values, { setSubmitting }) => {
          fetch('https://api.roadster.com/v1/california_privacy', {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
          })
          .then(() => {
            setSubmitting(false)
            setComplete(true)
          })
          .catch(() => { })
        }}
      >
        {({ isSubmitting }) => (
          <Form
            className='mx-auto mt-3'
            style={{ maxWidth: '34rem' }}
          >
            <Field
              component={TextInput}
              name='email'
              label='Email'
              type='email'
              autoComplete='email'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              required={true}
            />
            <Field
              component={TextInput}
              name='phone'
              label='Phone Number'
              type='tel'
              autoComplete='tel'
              autoCorrect='off'
              required={true}
            />
            <fieldset className='mb-2'>
              <legend className='font-semi-bold mb-2'>
                Please check below to make the respective request:
              </legend>
              {/*
                <p className='text-red mb-2'>
                  Please select at least 1 checkbox
                </p>
              */}
              <Field
                component={Checkbox}
                name='checkbox1'
                className='py-1'
              >
                I confirm that I would like to request Roadster Inc. to not sell my personal information to third parties pursuant to the California Consumer Privacy Act of 2018.
              </Field>
              <Field
                component={Checkbox}
                name='checkbox2'
                className='py-1'
              >
                I confirm that I would like to request Roadster Inc. to delete my personal information pursuant to Section 1798.105 of the California Consumer Privacy Act of 2018.
              </Field>
              <Field
                component={Checkbox}
                name='checkbox3'
                className='py-1'
              >
                I confirm that I would like to request Roadster, Inc. to disclose with regard only to the last 12 months (pursuant to the California Consumer Privacy Act of 2018): the categories and specific pieces of personal information collected about me, the categories of sources from which the personal information is collected, the purpose for collecting or selling the personal information, and the categories of third parties with whom this information is shared.
              </Field>
            </fieldset>
            <Field
              component={TextInput}
              name='name'
              label='Full Name'
              autoCorrect='off'
              autoComplete='name'
              required={true}
            />
            <div className='text-center'>
              <p className='mb-3 text-sm'>
                Date: {new Date().toLocaleDateString()}
              </p>
              <Button
                disabled={isSubmitting}
                type='submit'
                variant='dark'
                minWidth={true}
                children='Submit'
              />
            </div>
          </Form>
        )}
      </Formik>
    }
    
    {isFormComplete &&
      <div className='my-5 text-md text-center'>
        Your information has been submitted.
      </div>
    }
  </>)
}

export default CCPAForm
