import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Container from '../components/container'
import Modal from '../components/modal'
import CCPAForm from '../components/ccpa_form'
import Seo from '../components/seo'

const PrivacyPage = () => (
  <Layout knockoutHeader={false}>
    <Seo
      title='Privacy Policy'
      description='This site is operated by Roadster and has been created to provide information about our company, the products and services we provide, and our mobile applications to our Service visitors.'
    />
    <div className='PageHead PageHead--light'>
      <h1 className='PageHead-title'>
        Privacy Policy
      </h1>
      <hr className='Divider' />
      <p className='text-sm mb-0'>
        Effective: February 11, 2021
      </p>
    </div>
    <div className='Section Section--condensed'>
      <Container size='sm'>
        <div className='LegalText'>
          <h2>
            Our Policy
          </h2>
          <p>
            Welcome to the web site (the “Site”) of Roadster, Inc. (“Roadster”, “we”, “us” and/or “our”). This Site is operated by Roadster and has been created to provide information about our company, the products and services we provide, and our mobile applications (together with the Site, the “Services”) to our Service visitors (“you”, “your”). This Privacy Policy sets forth Roadster’s policy with respect to information including personally identifiable data (“Personal Data”) and other information that is collected from visitors to the Site and Services. For your convenience, we have organized everything regarding your privacy at Roadster, all in one place.
          </p>
          <h2>
            Information We Collect
          </h2>
          <p>
            When you interact with us through the Services, we may collect Personal Data and other information from you, as further described below:
          </p>
          <p><span className='underline'>Personal Data.</span>
            'Personal Data' is defined as any information that you may provide to us that can be used to identify you individually, including, but not limited to, contact information, business address, home address, fax numbers, driver's license number, state identification card number, bank account number, credit card or debit card number, passport number, alien registration number, as well as any company account number that we assign to you.
          </p>
          <p><span className='underline'>Non-Identifiable Data.</span>
            You can use certain Services to obtain certain information without revealing any personally identifiable information. During these types of visits, we may collect, analyze, or share such information on an anonymized basis. We use this information to generate statistics and measure the activity of our Services in order to improve the usefulness of the Services and the customer experience.
          </p>
          <p><span className='underline'>Personal Data That You Provide Through the Services.</span>
            We collect Personal Data from you when you voluntarily provide such information, such as when you contact us with inquiries, respond to one of our surveys, register for access to the Services or use certain Services. Wherever Roadster collects Personal Data we make an effort to provide a link to this Privacy Policy.
          </p>
          <p>
            By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. If you provide Personal Data to the Services, you acknowledge and agree that such Personal Data may be transferred from your current location to the offices and servers of Roadster and the authorized third parties referred to herein located in the United States.
          </p>
          <h3>
            Other Information:
          </h3>
          <p><span className='underline'>Non-Identifiable Data.</span>
            When you interact with Roadster through the Services, we receive and store certain personally non-identifiable information. We may passively collect information such as browser type, ISP, referring/exit pages, operating system, access date/time stamp, and clickstream data. We use this information to improve the usability of our Services. Such information is collected passively using various technologies and cannot presently be used to specifically identify you. Roadster may store such information itself or such information may be included in databases owned and maintained by Roadster affiliates, agents, or service providers. The Services may use such information and pool it with other information to track, for example, the total number of visitors to our Site, the number of visitors to each page of our Site, and the domain names of our visitors' Internet service providers. It is important to note that no Personal Data is available or used in this process.
          </p>
          <p>
            In operating the Services, we may use a technology called “cookies, clear gifs, and other technologies.” A cookie is a piece of information that the computer or mobile device, that hosts our Services gives to your browser when you access the Services. Our cookies help provide additional functionality to the Services and help us analyze Services usage more accurately. For instance, our Site may set a cookie on your browser that allows you to access the Services without needing to remember and then enter a password more than once during a visit to the Site. In all cases in which we use cookies, we will not collect Personal Data except with your permission. Most web browsers automatically accept cookies and session IDs, but you may be able to disable cookies by making the appropriate selection from your browser options. Note that by doing so, the functionality we can provide may be limited. On most web browsers, you will find a “help” section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. We recommend that you leave cookies turned on because they allow you to take advantage of some of the Service features.
          </p>
          <p><span className='underline'>Aggregated Personal Data.</span>
            In an ongoing effort to better understand and serve the users of the Services, Roadster often conducts research on its customer demographics, interests and behavior based on the Personal Data and other information provided to us. This research may be compiled and analyzed on an aggregate basis, and Roadster may share this aggregate data with its affiliates, agents, and business partners. This aggregate information does not identify you personally. Roadster may also disclose aggregated user statistics in order to describe our services to current and prospective business partners, and to other third parties for other lawful purposes.
          </p>
          <p><span className='underline'>Location Information.</span>
            Our Service may collect and use your location information (for example, by using the GPS on your mobile device) to provide certain functionality of our Service. If you choose to enable our location features, your location information may be publicly displayed within the Service. Please keep in mind that other users can see this information about you, and they may use it or disclose it to other individuals or entities outside of our control and without your knowledge. Your location information may be subject to abuse, misuse, and monitoring by others, so please be careful if you choose to enable location functionality. We may also use your location information in an aggregate way, as described above in the “Aggregated Personal Data” section.
          </p>
          <p><span className='underline'>Analytics; Do Not Track Signals.</span>
            We may (and we may allow third party service providers to) use cookies or other technologies to collect information about your browsing activities over time and across different websites following your use of the Services. For example, we use Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies to help the website analyze how users use the Site and enhance your experience when you use the Service. For more information on how Google uses this data, go to www.google.com/policies/privacy/partners/. Our Service currently does not respond to “Do Not Track” (DNT) signals and operates as described in this Privacy Policy whether or not a DNT signal is received. If we do so in the future, we will describe how we do so in this Privacy Policy.
          </p>
          <h2>
            Our Use of Your Personal Data and Other Information
          </h2>
          <p>
            Roadster uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such services and to monitor your use of such services. Roadster and its subsidiaries and affiliates (the “Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users and to improve the Services. Roadster and its affiliates may use this information to contact you in the future to tell you about services we believe will be of interest to you. If we do so, each marketing communication we send you will contain instructions permitting you to “opt-out” of receiving future marketing communications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.
          </p>
          <p>
            If Roadster intends on using any Personal Data in any manner that is not consistent with this Privacy Policy, you will be informed of such anticipated use prior to or at the time at which the Personal Data is collected.
          </p>
          <h2>
            Our Disclosure of Your Personal Data and Other Information
          </h2>
          <p>
            Roadster is not in the business of selling your information. We consider this information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below.
          </p>
          <p><span className='underline'>Business Transfers.</span>
            As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.
          </p>
          <p><span className='underline'>Related Companies.</span>
            We may also share your Personal Data with our Related Companies for purposes consistent with this Privacy Policy.
          </p>
          <p><span className='underline'>Agents, Consultants and Related Third Parties.</span>
            Roadster, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases and processing payments. When we employ another entity to perform a function of this nature, we only provide them with the information that they need to perform their specific function.
          </p>
          <p><span className='underline'>Service-related Disclosures.</span>
            We may share your information with third parties as may be necessary to provide certain Services to you. For example, if you ask us to contact a dealership to request a price quote from that dealership, we will share your information (including Personal Data) with that dealership (or its designee). If the relevant car listing was provided by a car manufacturer on behalf of a dealer, we may also share your information (including Personal Data) as part of the request with the automobile manufacturer and/or its affiliates. Similarly, if through the Service you request additional information from a financial institution, we will share your information (including Personal Data) with that institution. After the information is provided to such third parties, the subsequent use or disclosure of such information is subject to those third parties' privacy policies and practices and we do not control their subsequent use or disclosure of information.
          </p>
          <p><span className='underline'>Legal Requirements.</span>
            Roadster may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Roadster, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability.
          </p>
          <h2>
            Your Choices
          </h2>
          <p>
            You can visit the Site without providing any Personal Data. If you choose not to provide any Personal Data, you may not be able to use certain Services. If you obtain Services through us, we will use and share information we collect from or about you in accordance with our Privacy Notice, found here. California residents can view the California-specific opt-out notice here. These notices supplement this Privacy Policy by offering you certain choices with respect to the use and sharing of your financial personal information. In addition, you may contact us at privacy@roadster.com to learn how to access or delete your personal information and exercise certain other data rights you may have with regard to your personal information.
          </p>
          <h2>
            Exclusions
          </h2>
          <p>
            This Privacy Policy does not apply to any Personal Data collected by Roadster other than Personal Data collected through the Services. This Privacy Policy shall not apply to any unsolicited information you provide to Roadster through the Services or through any other means. This includes, but is not limited to, information posted to any public areas of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and Roadster shall befree to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
          </p>
          <h2>
            Children
          </h2>
          <p>
            Roadster does not knowingly collect Personal Data from children under the age of 18. If you are under the age of 18, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on the Services without their permission. If you have reason to believe that a child under the age of 18 has provided Personal Data to Roadster through the Services, please contact us, and we will endeavor to delete that information from our databases as quickly as possible.
          </p>
          <h2>
            Links to Other Web Sites
          </h2>
          <p>
            This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by Roadster (the “Third Party Sites”). The policies and procedures we described here do not apply to the Third-Party Sites. The links from the Services do not imply that Roadster endorses or has reviewed the Third-Party Sites. Because Third-Party Site features are hosted by third parties, when you leave our environment, your interactions with these third-party apps, sites, or features are governed by the privacy policy and practices of the respective company and not this Privacy Policy. We suggest contacting those sites directly for information on their privacy policies.
          </p>
          <h2>
            Security
          </h2>
          <p>
            Roadster takes reasonable steps to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. To protect the confidentiality and security of your personal information transmitted to us, we maintain appropriate administrative, technical, and physical safeguards that comply with applicable federal standards. We restrict access to the personal information obtained from our website, mobile application, products, and services to only those employees, agents, and contractors who need it to do their jobs. If you choose to complete and submit Persona Data to us, or to access your account through our website or mobile application, your personally identifiable information will be transmitted via an encryption process. We also require our service providers with whom we contract to protect customer information, and to use the information they collect or receive for the sole purpose of providing the services they provide for us. Please note, however, that no data transmission over the Internet or other network can be guaranteed to be 100% secure. Therefore, you should take special care in deciding what information you send to us via email or when disclosing any Personal Data to Roadster via the Internet It is your responsibility to keep your assigned account number, your username and password confidential and safe. We will retain your information as needed for the purposes of servicing your relationships with us, if any, and for internal analysis in compliance with applicable laws and regulations.
          </p>
          <h2>
            Other Terms and Conditions
          </h2>
          <p>
            Your access to and use of the Services is subject to the Terms of Service at <Link to='/terms_of_service'>https://roadster.com/terms_of_service</Link>
          </p>
          <h2>
            Changes to Roadster's Privacy Policy
          </h2>
          <p>
            The Services and our business may change from time to time. As a result, at times it may be necessary for Roadster to make changes to this Privacy Policy. As permitted by law, Roadster reserves the right to update, change, adjust, or modify this Privacy Policy at any time and from time to time without prior notice. Any revisions will be posted in a timely manner. Please review this policy periodically, and especially before you provide any Personal Data. This Privacy Policy was last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
          </p>
          <h2>
            Access to Information: Contacting Roadster
          </h2>
          <p>
            To keep your Personal Data accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Services.
          </p>
          <p>
            Please also feel free to contact us if you have any questions about Roadster’s Privacy Policy or the information practices of the Services.
          </p>
          <p>
            You may contact us (1) by email at: <a href='mailto:privacy@roadster.com'>privacy@roadster.com</a>, or (2) in writing at the following address:
          </p>
          <p>
            Roadster, Inc.
            <br />
            300 De Haro Street, Suite 334
            <br />
            San Francisco, CA 94103
          </p>
          <h2>
            Notice to California Residents – Your California Privacy Rights
          </h2>
          <p>
            Privacy Notice for California Residents can be found <Link to='/privacy_california'>here</Link>.
          </p>
          <p>
            “Do Not Sell My Info” Notice for California Residents can be found <Link to='/do_not_sell_california'>here</Link>.
          </p>
          <p>
            California Consumer Privacy Act of 2018 (CCPA) requests (Request to Delete, Request to Know, Request to Opt-Out) for California Residents:
            {' '}
            <Modal
              size='sm'
              title='California Consumer Privacy Act of 2018 (CCPA) request for California Residents'
              trigger={(
                <button
                  type='button'
                  className='opacity-60 hover:opacity-100 font-semi-bold'
                >
                  Request Here
                </button>
              )}
            >
              <CCPAForm />
            </Modal>
          </p>
          <h2>
            Notice to Connecticut Residents – Your Connecticut Privacy Rights
          </h2>
          <p>
            Pursuant to Connecticut General Statutes Sec. Section 42-471, it is the policy of Roadster to protect the privacy of your personal information by doing the following:
          </p>
          <ol>
            <li>
              Safeguarding any data, computer files and documents containing your personal information from any misuse by third parties;
            </li>
            <li>
              Destroying, erasing or making unreadable any data, computer files and documents containing your personal information prior to their disposal;
            </li>
            <li>
              Protecting the confidentiality of your Social Security number and other personal information;
            </li>
            <li>
              Prohibiting the unlawful disclosure of your Social Security number and other personal information;
            </li>
            <li>
              Limiting and/or restricting the access to your Social Security number and other personal information to other third parties; and
            </li>
            <li>
              Limiting the access of your Social Security number and other personal information only to our employees who need access to your personal information in order to allow us to provide legal services to you, limiting the access of these employees to extent that it is needed, and otherwise imposing restrictions and reasonable safeguards on our employees to prevent them from accessing your personal information.
            </li>
          </ol>
        </div>
      </Container>
    </div>
  </Layout>
)

export default PrivacyPage
